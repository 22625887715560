import React from "react"
import { Container } from "./Container"
import { Image } from "./Image"
import { ContactInfo } from "./ContactInfo"

export const HeroSection = () => {
  return (
    <section
      id="heroSection"
      className="w-full py-6 flex justify-center items-center bg-primary-lighter border-t-.5  border-b-.5 border-secondary-lightest"
    >
      <Container>
        <div className="flex flex-col items-center justify-center">
          <Image
            filterByText="hero"
            className="w-1/2  p-4 md:w-1/4"
            imgClassName="rounded-full"
          />
          <div className="flex flex-col ">
            <h1 className="text-2xl font-bold font-serif mt-2 py-1 text-center md:text-4xl">
              Elizabeth Vincent M
            </h1>
            <h2 className="text-lg font-medium py-1 text-center">
              Software Developer
            </h2>
            <h3 className="py-1 text-center">Kerala, India</h3>
          </div>
        </div>
        <ContactInfo />
      </Container>
    </section>
  )
}

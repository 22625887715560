import React from "react"
import SEO from "../components/seo"
import { HeroSection } from "../components/Hero"
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { ProjectList } from "../components/ProjectList"

const IndexPage = () => (
  <main className="flex flex-col justify-start">
    <SEO title="Home" />
    <Header />
    <HeroSection />
    <ProjectList />
    <Footer />
  </main>
)

export default IndexPage

import React from "react"
import { Container } from "./Container"
import { ProjectCard } from "./ProjectCard"

export const ProjectList = () => {
  const projects = require("../data/ProjectItems.json")
  return (
    <section className="mt-10 mb-20">
      <Container>
        <div className="flex flex-wrap justify-center">
          {projects.data.map((project, idx) => (
            <ProjectCard project={project} />
          ))}
        </div>
      </Container>
    </section>
  )
}

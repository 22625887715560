import React from "react"
import { Image } from "./Image"

export const ProjectCard = ({ project }) => {
  return (
    <div className="card w-full py-4 px-4 mb-4 md:mb-8 md:w-1/2 lg:w-1/3">
      <article className="h-full bg-primary-lightest cursor-pointer flex flex-col justify-start shadow-card hover:shadow-cardHover">
        <div className="flex-none w-full h-auto mb-6">
          <Image
            filterByText={project.pImg}
            className="aspect-ratio-16/9 relative overflow-hidden bg-secondary-lightest rounded-t-sm"
            imgClassName="block absolute w-full h-auto"
          />
        </div>
        <div className="px-6 flex flex-col flex-1 justify-between">
          <div className="flex-1 mb-3">
            <h2 className="font-bold text-xl">{project.pName}</h2>
            <p className="py-1 leading-normal md:text-md">{project.pDesc}</p>
          </div>
          <div className="mb-3 text-sm flex flex-wrap rounded">
            {project.pTech.map(tech => (
              <div className="capitalize pr-2 rounded-lg bg-primary text-white p-2 mr-2 mb-2">
                {tech}
              </div>
            ))}
          </div>
          <div className="mb-6 flex w-full items-center flex-row">
            <a
              href={project.pCode}
              target="_blank"
              rel="noopener noreferrer"
              title="view code"
              className="text-center mr-3"
            >
              <div className="text-sm text-primary-dark hover:font-semibold">
                View Code
              </div>
            </a>
            <a
              href={project.pLink}
              target="_blank"
              rel="noopener noreferrer"
              title="view code"
              className="text-center"
            >
              <div className="text-sm text-primary-dark hover:font-semibold">
                View Project
              </div>
            </a>
          </div>
        </div>
      </article>
    </div>
  )
}

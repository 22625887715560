import React from "react"
import EmailIcon from "../assets/email.svg"
import GitHubIcon from "../assets/github.svg"
import LinkedInIcon from "../assets/linkedin.svg"

export const ContactInfo = () => {
  const iconClasses =
    "w-8 h-8 text-primary-dark fill-current m-6 hover:text-tertiary-dark"
  const contactWrapperClasses = "flex flex-col items-center"
  return (
    <div className="flex justify-center">
      <a
        href="mailto:elizabethvincentm@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
        className={contactWrapperClasses}
      >
        <EmailIcon className={iconClasses} />
      </a>
      <a
        href="https://github.com/elizabethvincentm"
        target="_blank"
        rel="noopener noreferrer"
        className={contactWrapperClasses}
      >
        <GitHubIcon className={iconClasses} />
      </a>
      <a
        href="https://www.linkedin.com/in/elizabeth-vincent-6b6876190/"
        target="_blank"
        rel="noopener noreferrer"
        className={contactWrapperClasses}
      >
        <LinkedInIcon className={iconClasses} />
      </a>
    </div>
  )
}
